import React, { ReactElement } from "react"

import Container from "@ecom/ui/components/Container"
import Box from "@material-ui/core/Box"

import * as styles from "./conditions.module.scss"

import { ICONS, positions } from "./helpers"
import { ConItemWithTooltip } from "./ConItems/conItemWithTooltip"
import { ContItem } from "./ConItems/conItem"

const getItems = (rate?: string): ConditionsItem[] => [
  {
    img: "icon_deposit",
    title: `До ${rate}%`,
    desc: "к ставке вклада",
  },
  {
    img: "icon_cashback",
    title: "Кэшбэк до 10%",
    desc: "",
  },
  {
    img: "icon_cash_withdraw",
    title: "Снятие наличных",
    desc: "в рассрочку",
  },
  {
    img: "icon_bag",
    title: "Более 260 000",
    desc: "магазинов партнеров",
  },
]

export type ConditionsItem = {
  img: keyof typeof ICONS
  title: string
  desc: ReactElement | string
  hint?: string
}

export type ConditionsProps = {
  items?: ConditionsItem[]
  head?: string
  rate?: string
  orderNum?: string
  url?: string
}

const cashback_500 = "https://halvacard.ru/order/partners/cashback-500/"

export default function Conditions({
  items,
  head = "Невероятные условия и бесплатная доставка карты",
  rate,
  orderNum,
  url,
}: ConditionsProps) {
  const current_items = items || getItems(rate)
  const itemsRender = current_items.map(({ img, title, desc, hint }, i) =>
    hint ? (
      <ConItemWithTooltip
        key={i}
        img={img}
        title={title}
        desc={desc}
        hint={hint}
        position={positions[i]}
      />
    ) : (
      <ContItem key={i} img={img} title={title} desc={desc} />
    )
  )

  const titleConditions =
    url === cashback_500 ? (
      <>
        Карта «Халва» вдохновляет на супершопинг, ведь с<br /> ней легко делать покупки
      </>
    ) : (
      head
    )

  return (
    <section className={styles.root} data-exclude={orderNum}>
      <Container>
        <h2 className={styles.head}>{titleConditions}</h2>
        <Box component="ul" display="flex" flexWrap="wrap" justifyContent="center">
          {itemsRender}
        </Box>
      </Container>
    </section>
  )
}
